import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Button1 from './Button1';
import FormItems from './FormItems';
import { withRouter } from 'react-router';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class EntryMail extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
		    email: "",
		    isError: false,
		    open: false,
		    setOpen: false,
		}
		this.submitForm =  this.submitForm.bind(this);
		this.updateEmail = this.updateEmail.bind(this);
		this.testForm = this.testForm.bind(this);
		this.handleClickOpen = this.handleClickOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
    }
    
    useStyles = makeStyles((theme) => ({
        "dialog": {
			color:'red',
			fontSize:'20px',
        }
    }));
    
    handleClickOpen = () => {
        this.setState( { open: true })
     };
    
    handleClose = () => {
        this.setState( { open: false })
    };
    
    updateEmail = (event) => {
        console.log(event.target.value);
        this.setState( { email: event.target.value })
    }
    
    submitForm = () => {
        let mail = this.state.email;
        
        if(mail === ''){
            this.setState( { 
                isError: true,
            })
            document.getElementById("p1").style.display ="flex";
            
        }else{
            let params = new URLSearchParams();
            params.append('mail', mail);
            
            axios.post('/api/mail.php', params)
              .then(response => {
                this.props.history.push('/mailComp');
              }).catch(error => {
                console.log(error);
                alert(error);
              });
        }
        
    }
    
    testForm = () => {
        let mail = this.state.email;
        
        if(mail === ''){
            this.setState( { 
                isError: true,
            })
            document.getElementById("p1").style.display ="flex";
           
        }else{
            this.props.history.push('/mailComp');
        }
        
    }
    
    componentDidMount = () => {
        document.getElementById("p1").style.display ="none";
     };
     
    
    render() {
        const classes = this.useStyles;
        
        return (
            
            <div id="wrapper">
                <Header />
                <main>
                <div  className="box">
                    <h1>仮会員登録</h1>
                    
                   <Alert id="p1" severity="error">
                        <AlertTitle>Error</AlertTitle>
                        メールアドレスを入力してください
                    </Alert>
                    
                    <div className="login_area">
                        <div className="form_area">
                            <FormItems 
                                update={this.updateEmail} 
                                val={this.state.value}
                                error={this.state.isError}
                            />
                        </div>
                        
                        <p>
                        	<Button1 submit={this.submitForm} text='メールを送信' />
                        </p>
                    </div>
                </div>
                
                <div>
                    <Dialog
                        className={classes.dialog}
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                    <DialogContent>
                            メールアドレスを入力してください
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} color="primary" autoFocus>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                </main>
                <Footer />
            </div>

        )
        
    }
		
}

export default withRouter(EntryMail)
