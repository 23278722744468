import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Button1 from '../common/Button1';

const Tos = () => {
	return (
		<React.Fragment>
			<Header />
				<div className="box tos_area">
					<section className="">
						<h1>利用規約</h1>
						<p className="tos_text"><span className="note">【重要】</span> 利用規約をよくお読み頂き、同意した方のみ仮会員登録手続きにお進み下さい。</p>
					</section>
				    <section className="tos_contents">
				    	
						<h2 align="center">
							ＣＩＳＣＯ・ビジネス・シェアクラブ（仮称）会則　
						</h2>
						<h2>
							＜第１章＞総則
						</h2>
							<h3>第１条 （名称並びに事業主体）</h3>
							
						<p>
							　本会の名称は、「ＣＩＳＣＯビジネスシェアクラブ」（仮称）（以下「本クラブ」という）とし、本クラブ運営の事業主体をＡＳＡＨＩ　ＰＯＳＩＳＴ－１株式会社（以下「会社」という）とします。
						</p>
							<h3>第２条 （所在地）</h3>
						<p>
							　本クラブの本部事務所は、会社の横浜支店所在地（〒226-0026神奈川県横浜市緑区長津田町5764-1）とします。
						</p>
							<h3>第３条 （理念・目的）</h3>
						<p>
							　本クラブは、会社並びに本クラブの会員である事業者の有する人・物・情報等の経営資源を相互に最有効活用できる場と機会を提供し合うことにより、会員である事業者の安定した事業の運営並びに会員相互の交流により、事業運営上の不安や障害を取り除き、もって会員各々の独立した事業主体としての経営の安定化に寄与することを目的とします。
						</p>
							<h3>第４条 （運営管理）</h3>
						<p>
							　本クラブの運営並びに会員の利用するシステムの維持管理は、会社が業務として行うものとします。
						</p>
						<h2>
							<a name="_Hlk40177853">＜第２章＞会員・会費</a>
						</h2>
						<p>
							<h3>第５条 （会員資格並びに入会承認等）</h3>
						</p>
						<p>
							１ 本クラブへの入会資格は、下記のとおりとします。
							<br/>
						</p>
						<p className="center">記</p>
						<p>
							ア 日本国内において住・居所を有する法人又は個人であって、継続的に事業を営んでいること
						</p>
						<p>
							イ 前項の事業の遂行に必要な法令上の免許・認可・許可を取得し、又は資格を有していること
						</p>
						<p>
							ウ ア項の事業の遂行に必要な知識・経験・経歴を有していること
						</p>
						<p>
							エ 本クラブへの入会並びに入会後の本クラブの行う事業への参加するのに必要なインターネット設備を有し、又は設置可能な環境にあること
						</p>
						<p>
							オ ア項の事業に関する直近２期分の決算書、税務申告書又は之に代わる事業の概要の報告書の提出が可能であること
						</p>
						<p>
							２
							本クラブへの入会を希望する者（以下「入会希望者」という）は、本会則に同意したうえで、会社が別途指定するウェブサイト（以下「本サイト」という）又は入会申込書による入会、申出を行い、これを会社が承認することにより会員となります。
							<br/>
							　ただし、入会希望者が未成年その他の制限行為能力者である場合には、別途親権者等法定代理人の承諾書の提出を求める場合があります。また、会社が入会審査のため個別の面談の必要性を認めるときは、これに従うものとします。
						</p>
						<p>
							３ 入会希望者は、入会手続き完了後、会員資格を取得します。
						</p>
							<h3>第６条 （会費等）</h3>
						<p>
							　本クラブの会費は、無償とします。
							<br/>
							　ただし、本クラブが別途定めるオプションサービスの提供についてその使用料・利用料を定めたときは、別途使用料・利用料が発生します。
						</p>
						<h2>
							＜第３章＞会員サービスと契約
						</h2>
							<h3>第７条 （会員サービス）</h3>
						<p>
							　会員は、本クラブに入会することにより、本クラブの運営する本サイトを介して、事業者として次の各号に定めるビジネス情報の提供サービスを受けることができます。
							<br/>
						</p>
							<p className="center">記</p>
						<p>
							１ 元方事業者（発注者）情報
						</p>
						<p>
							・会社または会社の提携事業者が<a name="_Hlk40177967">保有するプロジェクト（その全部又は一部）情報</a>
						</p>
						<p>
							・会員事業者の保有するプロジェクト（その全部又は一部）情報
						</p>
						<p>
							２ 請方事業者（受注者）情報
						</p>
						<p>
							・会員事業者の保有するプロジェクトに対応できる事業能力、資格、経歴等に関する情報
						</p>
						<p>
							・会員事業者の保有するプロジェクトに対応できる物的設備・重機・車輛・機材・資材等に関する情報
						</p>
						<p>
							・会員事業者の保有するプロジェクトに注力できる役務・労務の内容、人員並びに工期等の情報
						</p>
						<p>
							３ ビジネスチャンスにかかる情報
							<br/>
							　会員は、元方事業者（発注者）として、あるいは請方事業者（受注者）として、当該プロジェクトの全部または一部を遂行させ又は遂行する契約に参加するための情報
						</p>
						<h3>
							第８条 （元方事業者（発注者）情報の提供）
						</h3>
						<p>
							　本クラブの会員は、元方事業者（発注者）として実施又は参画しているプロジェクトの全部又は一部について、本クラブの会員に対し、当該プロジェクトの概要（必要とされている業務・資材・物品等並びに契約条件の概要）を本クラブが指定した書式に従って記載し、本クラブの運営する本サイトに掲示して当該プロジェクトの参加事業者を募集することができます。
						</p>
						<h3>第９条 （請方事業者（受注者）情報）</h3>
						<p>　本クラブの会員は、請方事業者（受注者）として、本クラブの指定した書式に従って、事業者の事業の概要、提供できる役務・労務・資材・物品並びに契約条件の概要を本クラブの運営する本サイトに掲示して、元方事業者（発注者）並びにそのプロジェクトを応募するものとします。</p>
						<h3>
							第１０条 （掲載情報の真実性の保証）
						</h3>
						<p>
							１
							本クラブの会員が、前２条の規定により、本クラブの運営する本サイトに掲示する情報については、掲載事項の内容の真実性について責任を持ち、保証するものとします。
						</p>
						<p>
							２
							万一、前項の掲示された情報について内容が事実に反し、あるいは過大又は過小であることによって、本クラブの会員が損害を受けたときは、虚偽・過大・過小の情報を掲示した会員は損害を受けた会員に対し、その損害を賠償する責任を負い、本クラブ並びに会社は一切の責任を負いません。
						</p>
						<h3>
							第１１条 （交渉と仲介並びに成約）
						</h3>
						<p>
							１
							本クラブは、本クラブの運営する本サイトを提供することにより、前２条による元方事業者（発注者）たる会員と請方事業者（受注者）による会員間におけるプロジェクトの実施・推進に関する契約が出来るよう協力します。
						</p>
						<p>
							２
							本クラブの会員である元方事業者（発注者）と請方事業者（受注者）は、前３条により元方事業者（発注者）より提供されたプロジェクトに関する情報と請方事業者（受注者）の情報をもとに本クラブの本サイトに通じて契約条件等について直接交渉するものとします。
						</p>
						<p>
							３
							本クラブの会員である元方事業者（発注者）と請方事業者（受注者）間の前記交渉の経過並びに結果については、本クラブの本サイトを通じて本クラブ所定の書式に従い、本クラブに報告するものとし、本クラブは本クラブの会員にその報告を求めることができるものとし、必要に応じて本サイトの情報を閲覧する事ができるものとします。
						</p>
						<h3>
							第１２条 （成約並びに契約書の作成）
						</h3>
						<p>
							１
							本クラブの会員間に、前条の交渉により契約条件について合意が成立したときは、本クラブ所定の書式により合意内容に応じた契約書（以下「基本契約書」という）を作成し、記名・押印していただくことにより基本契約が成立したものとします。
						</p>
						<p>
							２
							契約当事者間において、基本契約書に記載されていない事項並びに基本契約書の履行の細目を定めた事項（以下「別途合意」又は「別途合意書」という）について合意したときは、別途合意書の定めに従うものとします。
						</p>
						<p>
							３ 基本契約に定める代金の支払い方法は、元方事業者（発注者）から本クラブを経由して請方事業者（受注者）に対して支払うものとします。
						</p>
						<h3>
							第１３条 （基本契約の変更並びに解釈等）
						</h3>
						<p>
							１
							契約当事者間において、前条１項において定めた基本合意書の内容を変更する合意が成立したときは、契約当事者は本クラブに対し、書面にて変更した条項並びにその内容（以下「変更合意」という）について連署にて届出をし、本クラブが承認することによりこれを変更することが出来ます。
						</p>
						<p>
							２
							基本合意書の内容に反し、又はこれと矛盾する別途合意書は、本クラブに対する関係においては「基本合意書」の内容を優先して適用します。前項の変更合意のうち、本クラブの承認を得ない部分についても同様とします。
						</p>
						<p>
							３
							契約当事者間における基本契約に関し、書面によらない合意（口頭・電話・打合せその他）は、相手方並びに本クラブに対する関係においては、効力を有しないものとします。
						</p>
						<h3>
							第１４条 （契約の履行義務）
						</h3>
						<p>
							１ 本クラブの会員は、第１１，１２，１３条に基づき基本契約を終結したときは、基本契約書に従い、これを誠実に履行するものとします。
						</p>
						<p>
							２
							本クラブの会員の基本契約の不履行によって、相手方に損害を生ぜしめたときは、基本契約の内容に従って契約の解除、損害賠償又は原状回復等の義務を負うものとします。
						</p>
						<p>
							３
							基本契約の当事者間において、基本契約の履行その他の契約条件をめぐって紛争を生じたときは、当事者間において解決するものとし、本クラブは原則としてこれに関知しないものとします。
							<br/>
							　但し、契約の条件や契約の成立にかかる事実であって、会員から本クラブに提供された本サイト上の情報について、裁判所の命令その他本クラブが正当と認めた事由があるときは、当事者の双方又は一方に開示することがあります。
						</p>
						<h2>
							＜第４章＞システム使用料等
						</h2>
						<h3>
							第１５条 （システム使用料）
						</h3>
							<p>
							　会社と本クラブ会員間並びに本クラブ会員間又は本クラブ会員と本クラブ会員の紹介する事業者（以下「第三事業者」という）間において、第１１，１２条に基づき基本契約が成立したときは、本クラブの会員又は第三事業者は本クラブに対し、以下の類型毎に定められた所定の料率によるシステム使用料を支払うものとします。
							</p>
						<p className="center">記</p>
						<p>
							１）
							元方事業者会員のプロジェクトに関して、元方事業者（発注者）会員（甲）と請方事業者（受注者）会員（乙）間に基本契約が成立したときは、請方事業者（受注者）会員（乙）は本クラブに対し成約金額の２％を支払うものとします。
						</p>
						<p>
							２）
							請方事業者（受注者）会員（乙）の有するプロジェクトの全部又は一部に関して、第二次請方事業者会員（丙）と元方事業者（発注者）会員（甲）との間に、直接基本契約が成立したときは、第二次請方事業者会員（丙）は本クラブに対し契約金額の４％を、本クラブは請方事業者（受注者）会員（乙）に対し２％を支払うものとします。
						</p>
						<p>
							３）
							請方事業者（受注者）会員（乙）の有するプロジェクトの全部又は一部に関して、第二次請方事業者会員（丙）として基本契約が成立したときは、第二次請方事業者会員（丙）は本クラブに対しその契約金額の２％を支払うものとします。
							<br/>
							　この場合、乙にシステム使用料は発生しないものとします。
						</p>
						<p>
							４）
							３）において、当該プロジェクトの全部又は一部を分離して発注される等により、第二次請方事業者会員として丙が並列的に複数存在するときは、第二次請方会員である丙らは各自本クラブに対して、各自の契約金額の２％を支払うものとします。
							<br/>
							　この場合、乙にシステム使用料は発生しないものとします。
						</p>
						<h3>
							第１６条 （システム使用料の支払）
						</h3>
							<p>
							　前条のシステム使用料の支払時期並びに方法は、以下のとおりとします。
							</p>
						<p className="center">記</p>
						<p>
							１ システム使用料は、基本契約の成立時に契約金額に対する前条所定の比率で発生し、その支払時期は基本契約所定の代金額の支払時期とします。
						</p>
						<p>
							２
							支払方法は、第１２条３項により基本契約における所定の契約金額の支払義務者より本クラブを経由して相手方に対して支払いをなす際に、本クラブがシステム使用料相当額を差し引く方法により支払うものとします。
						</p>
						<p>
							３
							システム使用料の支払につき、本クラブの承認を得て個別の基本契約において書面により、前項と異なる支払時期・方法を定めたときは、これに従うものとします。
						</p>
						<h2>
							＜第５章＞その他
						</h2>
						<h3>
							第１７条 （秘密保持義務）
						</h3>
						<p>
							１
							本クラブ会員は、規約並びに本規約に基づく交渉や契約の締結に関連して知りえた他の当事者の技術上・経営上の一切の秘密を、他の当事者の書面による承諾がない限り、第三者に漏洩または開示してはなりません。
							<br/>
							　ただし、以下のものはこの限りではありません。
						</p>
						<p>
							ア 他の当事者から知得する以前にすでに所有していたもの
						</p>
						<p>
							イ 他の当事者から知得する以前にすでに公知のもの
						</p>
						<p>
							ウ 他の当事者から知得した後に自己の責によらない事由により公知とされたもの
						</p>
						<p>
							エ 正当な権限を有する第三者から秘密保持の義務をともなわずに知得したもの
						</p>
						<p>
							２ 前項の規定は、規約終了後も１０年間存続する。
						</p>
						<h3>
							第１８条 （個人情報保護）
						</h3>
						<p>
							１ 本クラブの会員は、相手方の個人情報を厳重に管理し、これを外部に漏洩しないものとします。
						</p>
						<p>
							２ 本クラブの会員が、相手方の個人情報を委託先等に配布する際は、事前に相手方の了解を得るものとします。
						</p>
						<p>
							３
							本クラブ並びに会社は、本クラブが本サービスの提供のため、会員から取得した個人情報（個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述などによって特定の個人を識別できることとなるものを含みます）については、個人情報取扱事業者として、別途「個人情報の取り扱いについて」の指針を定めこれに従い、個人情報の保護に関する法律、その他関係法令を順守するものとします。
						</p>
						<p>
							４ 本クラブ並びに会社は、本サービスの提供のため、会員の個人情報を提携事業者に開示する場合もあります。
						</p>
						<h3>
							第１９条 （登録事項の変更）
						</h3>
						<p>
							１ 会員は、入会申し込みにおいて本クラブに届出た内容に変更があった場合には、速やかに所定の変更の届け出を、本クラブに行うものとします。
						</p>
						<p>
							２ 会員が、前項の届け出を怠ったため、会員の住所確認等ができないときは、本サービスの提供を受けられない場合があります。
						</p>
						<h3>
							第２０条 （連絡事項）
						</h3>
						<p>
							１ 本クラブから会員に対する連絡事項の伝達は、次の各号に定める方法で行います。
						</p>
						<p>
							① 会員の登録のメールアドレス宛に電子メールを送信する方法
						</p>
						<p>
							② 会員の登録住所地に向けて郵便を発送する方法
						</p>
						<p>
							③ 本サービスを提供する本サイト（http://www.koshoshuri.com/）上に連絡事項を掲示する方法
						</p>
						<p>
							２
							本クラブが、前項の方法で連絡事項を告知した場合、当該連絡事項を会員が確認しなかった場合でも、それが本クラブの責めに帰すべき事由によるものでない限り、当該連絡事項は各通知が通常到達すべき時期を経過した後は、当該通知は各会員に到達したものとします。
						</p>
						<h3>
							第２１条 （禁止事項）
						</h3>
							<p>
							　会員は、本サービスの利用にあたっては次の各号に定める行為を禁止いたします。
							</p>
						<p>
							①本クラブの会員以外の第三者に、本サービスの提供を受けさせ又は受けさせようとする行為
						</p>
						<p>
							② 本サービスを通じ又は本サービスに関連した営利を目的とする行為
						</p>
						<p>
							③ ユーザＩＤ及びパスワードを不正に利用する行為
						</p>
						<p>
							④ 本クラブ・本クラブ会員会社又はその提携企業に対し、故意に損害を加える行為
						</p>
						<p>
							⑤ 本クラブ又は本クラブの会員の持つプロジェクトについて本クラブを介さずに直接契約する行為
						</p>
						<p>
							⑥ システム使用料の支払を怠ること
						</p>
						<p>
							⑦ 本クラブの本サービスと同種の行為を行うこと
						</p>
						<p>
							⑧ 本クラブの会員が、本クラブに提出する書類又は参加を希望するプロジェクトについて虚偽の申告をすること
						</p>
						<p>
							⑨
							本会則上の会員としての地位・権利並びに基本契約の締結によって発生した権利又は義務を、本クラブ又は相手方会員の書面による承諾なくして他に譲渡又は転貸しようとすること
						</p>
						<p>
							⑩ その他本規約の各条項に違反すること
						</p>
						<h3>
							第２２条 （期間）
						</h3>
							<p>
							　本規約の有効期限は、入会承認の日から１年間とするが、期間満了の１ケ月前までにいずれの会員からの書面による別段の申出がないときには、同一条件にもってさらに１年間自動的に更新するものとし、以後も同様とします。
							</p>
						<h3>
							第２３条 （退会）
						</h3>
							<p>
							　会員は、所定の退会申出書を会社に提出することにより、いつでも本クラブを退会することができます。
							</p>
						<h3>
							第２４条 （除名）
						</h3>
						<p>
							１ 本クラブは、会員が次の各号の一に該当すると認めた場合には、当該会員を除名することができるものとします。
							<br/>
						</p>
						<p className="center">記</p>
						<p>
							１） 本クラブ並びに本クラブの会員または会社の名誉、信用を毀損し、又は秩序を乱したとき
						</p>
						<p>
							２） 入会申込みの際、虚偽の事実を申告したとき
						</p>
						<p>
							３） 本クラブの事業を妨げ、又は妨げようとしたとき
						</p>
						<p>
							４） 本クラブの事業の利用について不正の行為をしたとき
						</p>
						<p>
							５） 本契約上の権利を他に譲渡、転貸もしくは転貸しようとしたとき
						</p>
						<p>
							６） 会員の役員または従業員が暴力団員、暴力団関係者又はその他反社会的勢力に所属しているものであると判明したとき
						</p>
						<p>
							７）
							会員が、自己、自己の役員若しくは従業員が反社会的勢力であることを告げたとき、またはこれらの者もしくはその他の関係者が反社会的勢力であることを背景とした不当な要求を行ったとき
						</p>
						<p>
							８） 会員が、本クラブに提出する書類または本クラブの事業の参加要項について虚偽の申告があった場合
						</p>
						<p>
							９） 本クラブと同種の事業を営み又は之を企画したとき
						</p>
						<p>
							１０） 犯罪その他信用を失う行為をしたとき
						</p>
						<p>
							１１） 本会則その他会社の定める規約に違反し除名処分を相当とする行為があると本クラブが判断したとき
						</p>
						<p>
							２ 除名処分を受けた会員は、除名の事由となった行為によって本クラブ又は会社または他の会員に与えた損害を賠償しなければなりません。
						</p>
						<h3>
							第２５条 （会員としての権利・義務の存続）
						</h3>
						<p>
							１
							本クラブの会員が期間の満了（第２２条）退会（第２３条）除名（第２４条）により会員としての資格を喪失した場合においても、基本契約に基づき取得した権利又は義務については、基本契約の定めに従い誠実に履行を完了し、システム使用料の支払を完了するものとします。
						</p>
						<p>
							２
							掲載情報についての保証義務（第１０条）秘密保持義務（第１７条）個人情報保護義務（第１８条）については、会員たる地位を失った後も５年間その効力を保持するものとします。
						</p>
						<h2>
							＜第６章＞雑則
						</h2>
						<h3>
							第２６条 （サービスの中断・中止）
						</h3>
						<p>
							１
							本クラブは、本サービスのシステムのメンテナンスその他、改善、復旧等のため、事前に予告し又は予告をせずに本サービスの提供を一時的に中断する場合があります。
						</p>
						<p>
							２
							各サービスを実施する提携会社のサービス廃止、又は事業の廃止により、本サービスの一部または全部の提供が困難となった場合、サービスの提供の一部または全部を中止する場合があります。
						</p>
						<h3>
							第２７条 （規約の改定）
						</h3>
						<p>
							１
							本クラブは、本サービスに関係する法令改正、社会情勢、経済情勢の変動その他の理由により、本規約の条項に定める内容が不相当となった場合には、本規約およびその細則その他の関連規約について、会員の承諾なくその内容を変更する事ができ、会員は予めこれに同意することとします。
						</p>
						<p>
							２ 本クラブは、規約等の変更を行った場合には、遅滞なく第２０条第１項に指定する方法で、各会員に周知します。
						</p>
						<p>
							３
							本クラブが、１項の規定により規約が改訂された場合には、規約の改定日より新規約が効力を生じ、その日から旧規約は効力を失うものとします。但し、第１５条に定めるシステム使用料に関する定めを改訂した場合には、次の支払日より改訂後の金額が適用されます。
						</p>
						<h3>
							第２８条 （管轄の合意）
						</h3>
							<p>
							　会員と本クラブとの間の本サービスに関する紛争については、東京地方裁判所を専属管轄とします。
							</p>
				    	
				    	
					</section>
					<div className="regist_area">
						<Link to="/entryMail" style={{ textDecoration: 'none' }}><Button1 text='同意して仮登録画面に進む' /></Link>
					</div>
				</div>
			<Footer />
		</React.Fragment>
	)
}

export default Tos