import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import headerLogo from '../../assets/img/header_logo.png';

const Header = () => {
	return (
		<header className="header">
			<img src={headerLogo} alt="ロゴ" />
		</header>
	)
}

export default Header