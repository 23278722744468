import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const RadioForm = (props) => {
    
  const [value, setValue] = React.useState('法人');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
	
	return (
	  	<React.Fragment>
	  	<div className="radio_area">
	  	  <label className="radio_title" htmlFor="belongs"><PermIdentityIcon /></label>
        <RadioGroup row className="radio_contents" aria-label="belongs" id="belongs" name="belongs" value={value} onChange={handleChange}>
          <FormControlLabel value="法人" control={<Radio />} label="法人" />
          <FormControlLabel value="個人" control={<Radio />} label="個人" />
        </RadioGroup>
      </div>
      </React.Fragment>

	)
}

export default RadioForm;