import React from 'react';
//入力フォーム
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';



const FormItems = (props) => {
	
	const useStyles = makeStyles((theme) => ({
        "form": {
			
			width:'90%',
			marginBottom:'20px',
			borderColor: '#afafaf'
			
        }
    }));
	
	const classes = useStyles();
	
	
	return (
		
		<React.Fragment>
			
			<div>
				<TextField
					className={classes.form}
					id="input-with-icon-textfield"
					label='メールアドレス'
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<MailIcon />
							</InputAdornment>
						),
					}}
				/>
			</div>
			
			<div>
				<TextField
					className={classes.form}
					id="input-with-icon-textfield"
					label='パスワード'
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LockIcon />
							</InputAdornment>
						),
					}}
				/>
			</div>
						
		</React.Fragment>
		
		
	)
}

export default FormItems