import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';
import FormItems from './FormItems';
import CheckBox01 from '../common/CheckBox01';
import Button1 from '../common/Button1';
import Button2 from '../common/Button2';


const Login = () => {
	
	const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
	
	
	return (
		
		<div id="wrapper">
			<Header />
			<main>
				<div  className="box">
					<h1>ログイン</h1>
					<div className="login_area">
						<div className="form_area">
							<FormItems />
						</div>
							<CheckBox01 />
							<p>
								<Button1 text='ログイン' />
							</p>
							<a className="forget_pass" href="#">パスワードをお忘れの方はこちら</a>
					</div>
					<hr />
					<div className="regist_area">
						<p className="small_font">初めてご利用される方はこちら</p>
						<Link to="/tos" style={{ textDecoration: 'none' }}><Button2 text='新規会員登録' /></Link>
					</div>
				</div>	
			</main>
			<Footer />
		</div>
		
		
	)
}

export default Login