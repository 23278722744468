import React from 'react';
// import { BrowserRouter, Route, Link } from 'react-router-dom';


//チェックボックス
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';



export default class CheckBox01 extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            isPublished: "true",
            checked: false,
		}
		this.togglePublished = this.togglePublished.bind(this);
		this.handleChange = this.handleChange.bind(this); 
    }
    
    togglePublished = () => {
        this.setState( { isPublished: !this.state.isPublished })
        
    }

  handleChange = (event) => {
    this.setState( { checked: !this.state.checked })
  };
    
    render() {
    	return (
    		<div>
        		<Checkbox
                    checked={this.state.checked}
                    onChange={this.handleChange}
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                 />
        	    <label className="checkboxA" htmlFor="checked">次回から自動でログインする</label>
    		</div>
    	)
        
    }
}

