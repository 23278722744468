import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import EntryMail from './components/userEntryMail/EntryMail';
import Login from './components/login/Login';
import Tos from './components/Tos/Tos';
import MailComp from './components/userEntryMailComp/MailComp';
import EntryMain from './components/userEntryMain/EntryMain';

const App = () => (
	<BrowserRouter>
		<div>
			<Route exact path="/" component={Login} />
			<Route path="/entryMail" component={EntryMail} />
			<Route path="/tos" component={Tos} />
			<Route path="/mailComp" component={MailComp} />
			<Route path="/entryMain/*" component={EntryMain} />
		</div>
	</BrowserRouter>
)




export default App;
