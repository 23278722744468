import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Button1 from '../common/Button1';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
		color:'#72bb36',
		margin:'auto',
    },
  },
  maru: {
    '& > svg': {
		color:'red',
		marginBottom:'-4px',
    },
  }
}));

function CheckCircleIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

const MailComp = () => {
	const classes = useStyles();
	return (
		<div id="wrapper">
			<Header />
				<div className="box mailcomp_area">
					<section className="success_area" style={{ marginBottom: '50px' }}>
						<div className="success_icon">
							<div className={classes.root}>
						      <CheckCircleOutlineIcon style={{ fontSize: 90 }} />
						    </div>
						</div>
						<h2 className="sub_text">ありがとうございます。<br />仮会員登録を承りました。</h2>
						<p className="sentence">お客様のメールアドレス宛に、確認の自動配信メールを送信いたしました。 
						<br />メールに記載されたURLをクリックし、会員情報登録手続きにお進みください。  </p>
					</section>
					
				    <section>
				    	<h2>
					    	<span className={classes.maru}>
					    		<FiberManualRecordIcon />
					    	</span>
				    	メールが届かないときは</h2>
		    		    <p>数時間経っても当社からのメールが届かない場合は、下記事項をご確認のうえ、
再度お手続きください。</p>
		    		    <h2>1．メールアドレスの記入ミスはないか </h2>
		    		    <p className="sentence">ご入力頂いたメールアドレスに誤りがある場合は、ご連絡を差し上げることができません。ご登録のメールアドレスをご確認のうえ、再度お手続きください。 </p>
		    		    <h2>2．迷惑メールフォルダに届いていないか </h2>
		    		    <p className="sentence">GmailやYahooなどのフリーメールアドレスをご利用のお客様は、「迷惑メールフォルダ」に自動的に振り分けられている場合があります。「迷惑メールフォルダ」をご確認ください。</p>
		    		    <h2>3．受信拒否設定になっていないか </h2>
		    		    <p className="sentence">ご利用のメールの設定が「URL付きのメールを受信しない」設定になっている場合、受信できるよう設定して下さい。また、「aaa＠xxxx.com」からのメールを許可するように設定して頂く必要がございます。 
						<br />設定は各電話会社のサービスメニューから設定して下さい。</p>
						
						<p>※設定方法は各電話会社のWebサイトをご確認下さい。 </p>
	
					</section>
					
				</div>
			<Footer />
		</div>
	)
}

export default MailComp
