import React, { Component } from 'react';

const EntryMain = () => {
	return (
		<React.Fragment>
		    <h1>本会員登録</h1>
			<p>こんにちは</p>
		</React.Fragment>
	)
}

export default EntryMain;