import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import footerLogo from '../../assets/img/footer_logo.png';

const Footer = () => {
	return (
		<footer className="footer">
		    <img src={footerLogo} alt="ロゴ" />
		    <p>Copyright © ASAHI POSIST-1株式会社 All Rights Reserved.</p>
		    
		</footer>
	)
}

export default Footer